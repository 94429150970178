'use strict';

var geoServerURL = 'https://geoapi.sgoblin.com/';

var OpenStreetMap_Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  maxZoom: 19,
  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
});
var mymap = L.map("mapid").setView([0, 0], 1);
OpenStreetMap_Mapnik.addTo(mymap);
var marker = L.marker([0, 0]).addTo(mymap);

var startValues = function selfFunction(q, domain='') {
  console.log(q);
  //if (document.getElementById('q').value == ''){
  if( q == '' ) {
    $.getJSON(geoServerURL+'ip').done(
      function(resp){
        selfFunction(resp.ip);
      }
    );
  } else {
    $.getJSON(geoServerURL, { q: q, domain: domain, type: 'json' }).done(function (data) {
      useValues(data);
    });
  }
};
var useValues = function useValues(data) {
  var userSeenDataTypes = ['ip', 'domain', 'org', 'city', 'country', 'continent'];
  for (var i in userSeenDataTypes) {
    document.getElementById(userSeenDataTypes[i]).innerHTML = data[userSeenDataTypes[i]];
  }

  var mapZoom;
  var markerBool = true;
  if (data.latitude == 0 && data.longitude == 0) {
    mapZoom = 1;
    markerBool = false;
    console.log("mapzoom 1");
  } else if (data.city == "Unknown" && data.country != "Unknown") {
    mapZoom = 4;
  } else if (data.city != "Unknown" && data.country != "Unknown") {
    mapZoom = 7;
  } else {
    mapZoom = 5;
  }

  mymap.flyTo([data.latitude, data.longitude], mapZoom);
  marker.remove()
  if (markerBool == true){
    marker.addTo(mymap);
    marker.setLatLng([data.latitude, data.longitude]);
  } /*else {
    //console.log('no marker');

  }*/
};

// Removed domain stuff while debugging
route(function (q, domain) {
  startValues(q, domain);
});

route.start(true)
//startValues('');

var queryInput = document.getElementById('q');
queryInput.addEventListener('input', function () {
  route(encodeURIComponent(queryInput.value));
});
var daform = document.getElementById('daform');
$('#daform').click(function(event){
  event.preventDefault();
});
daform.addEventListener('submit', function() {
  route(encodeURIComponent(queryInput.value));
});
//queryInput.addEventListener('input', function(){startValues(queryInput.value)})
